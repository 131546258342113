(function() {
    angular.module('EntrakV5').controller('buildingController', buildingController);

    function buildingController($scope, $rootScope, $state, KEY, Service, Api) {
        console.log('buildingController');

        var caller = Api.createApiCaller();
        $scope.btnStatus = {};

        $scope.cities = [];
        $scope.buildings = [];
        $scope.distributorId = null;

    /* first load */
        $rootScope.getLandlordId().then(function(id){
            $rootScope.loadingPage = 1;
            caller.call([Api.getBuildingPage(), Api.getCities()]).then(function(res){
                $scope.distributorId = res[0].id;
                $scope.buildings = res[0].buildings;
                $scope.buildings.forEach(updateHubNames);
                $scope.cities = res[1].map(function(c){
                    return {
                        id: c.id,
                        name: c.country + "/" + c.city
                    }
                });
                $scope.cities.sort(Service.getSorter("name"));
                $scope.locationDropdown.dataSource.data($scope.cities);
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalGetDataFail"));
            });
        });

        function updateHubNames(building){
            building.hubNames = building.hubs.map(function(h){
                return h.serialId;
            }).join(", ");
        }
    /* first load */

    /* step2 (building) */
        $scope.editBuildingWinData = {};
        $scope.editBuildingWinOpt = {
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            open: function(){
                $scope.$apply(function(){
                    $scope.btnStatus.saving = false;
                });
            }
        }
        $scope.locationDropdownOpt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "name",
            dataValueField: "id"
        }

        $scope.confirmEditBuilding = function(){
            $scope.btnStatus.saving = true;
            var name = $scope.editBuildingWinData.name;
            var lat = parseFloat($scope.editBuildingWinData.latitude) || 0;
            var long = parseFloat($scope.editBuildingWinData.longitude) || 0;
            if ($scope.editBuildingWinData.id){
                var action = Api.updateBuilding($scope.editBuildingWinData.id, name, $scope.editBuildingWinData.cityId, lat, long);
            } else {
                var action = Api.createBuilding(name, $scope.editBuildingWinData.cityId, "", lat, long);
            }
            caller.call(action).then(function(res){
                updateHubNames(res);
                Service.replaceArrItem($scope.buildings, res, true);
                $scope.editBuildingWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                alert(Service.translate("error.generalCreateUpdateBuilding"));
            });
        }
        $scope.editBuilding = function(building){
            if (building){
                $scope.editBuildingWin.title(Service.translate("building.popup.editBuilding"));
                $scope.editBuildingWinData.id = building.id;
                $scope.editBuildingWinData.name = building.name;
                $scope.editBuildingWinData.cityId = building.city.id;
                $scope.editBuildingWinData.hubs = building.hubNames;
                $scope.editBuildingWinData.latitude = building.latitude;
                $scope.editBuildingWinData.longitude = building.longitude;
            } else {
                $scope.editBuildingWin.title(Service.translate("building.addBuilding"));
                $scope.editBuildingWinData.id = null;
                $scope.editBuildingWinData.name = '';
                $scope.editBuildingWinData.cityId = $scope.cities[0].id;
                $scope.editBuildingWinData.latitude = '';
                $scope.editBuildingWinData.longitude = '';
            }
            setTimeout(function(){
                $scope.editBuildingWin.open().center();
            });
        }

        $scope.deleteBuilding = function(building){
            $rootScope.deletePopup.show("building.popup.deleteBuilding", "building.popup.deleteBuildingDesc", building.name, function(){
                caller.call(Api.deleteBuilding(building.id)).then(function(res){
                    Service.deleteArrItem($scope.buildings, building.id);
                    $rootScope.deletePopup.close();
                });
            }, function(err){
                if (err === KEY.ignore)
                    return;
                alert(Service.translate("error.generalDeleteFail"));
            });
        }
    /* step2 (edit building) */

        $scope.$on('$destroy', function() {
            console.log("buildingController destroy");
            caller.cancel();
        });
    }
})();
